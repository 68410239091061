<script setup lang="ts">
import type { AsyncDataRequestStatus } from "nuxt/app"

type Thresholds = {
  medium?: number
  good: number
}

const { thresholds = { good: 75, medium: 50 }, value } = defineProps<{
  value?: number
  status: AsyncDataRequestStatus
  thresholds?: Thresholds
  hideLabels?: boolean
}>()
// Dynamically compute widths for bad, medium, and good segments
const badWidth = computed(() => thresholds.medium ?? thresholds.good) // 0 → medium%
const mediumWidth = computed(
  () => (thresholds.medium ? thresholds.good - thresholds.medium : 0), // medium% → good%
)
const goodWidth = computed(() => 100 - thresholds.good) // good% → 100%
const formattedValue = computed(() => (value === 0 ? 2 : value))

const progressClass = computed(() => {
  if (value === undefined) return

  if (value < (thresholds.medium ?? thresholds.good)) {
    return "bg-graph-secondary"
  } else if (value < thresholds.good) {
    return "bg-graph-tertiary"
  }
  return "bg-graph-fifth"
})
</script>

<template>
  <div
    class="mb-4 w-full"
    :class="{
      'mt-1': hideLabels, //The handle will poke outside the container when labels are hidden without this
    }"
  >
    <!-- Labels above the bar -->
    <div
      v-if="!hideLabels"
      class="relative mb-2 flex h-5 w-full text-xs font-semibold text-slate-500"
    >
      <div class="absolute bottom-0 left-0 flex items-center">
        {{ $t("factor_indicator.bad") }}
      </div>
      <!-- The “good” label is attached to the medium range’s end, 
           so we position it near the boundary between medium and good. -->
      <div
        v-if="thresholds.medium"
        class="absolute bottom-0 flex items-center justify-end pr-0.5"
        :style="{ width: `${thresholds.medium}%` }"
      >
        <span class="block translate-x-1/2 tabular-nums">
          {{ $t("factor_indicator.good") }}
        </span>
      </div>

      <!-- The “excellent” label on the right -->
      <div class="absolute right-0 bottom-0 flex items-center">
        {{ $t("factor_indicator.excellent") }}
      </div>
    </div>

    <!-- Progress bar -->
    <div class="relative flex h-2 w-full items-center">
      <!-- Color segments -->
      <div
        class="flex h-full flex-1 items-center gap-0.5 overflow-hidden rounded-full"
      >
        <div
          class="bg-graph-secondary h-full"
          :style="{ width: `${badWidth}%` }"
        />
        <div
          v-if="thresholds.medium"
          class="bg-graph-tertiary h-full"
          :style="{ width: `${mediumWidth}%` }"
        />
        <div
          class="bg-graph-fifth h-full"
          :style="{ width: `${goodWidth}%` }"
        />
      </div>

      <!-- Progress handle -->
      <div
        class="absolute w-2 -translate-x-1/2 transform-gpu transition-all duration-300 ease-in-out"
        :style="{ left: `${formattedValue}%` }"
      >
        <div
          aria-hidden="true"
          class="relative mx-auto h-4 w-1 rounded-full ring-2 ring-white"
          :class="progressClass"
          data-state="closed"
        >
          <div
            aria-hidden="true"
            class="absolute size-7 -translate-x-[45%] -translate-y-[15%]"
          />
        </div>
      </div>
    </div>
  </div>
</template>
