<script setup lang="ts">
const { session } = useAuth()

const classObject = computed(() => {
  if (!session.value || !session.value.user) {
    return "border-slate-200 border-slate-400 bg-slate-200 text-slate-500 hover:bg-slate-200/90"
  }
  return {
    "border-slate-200 border-teal-500 bg-teal-100 text-teal-600 hover:bg-teal-200/90":
      session.value.user.progress > 0,
    "border-red-200 border-red-400 bg-red-200 text-red-500 hover:bg-red-200/90":
      session.value.user.progress < 0,
    "border-slate-200 border-slate-400 bg-slate-200 text-slate-500 hover:bg-slate-200/90":
      session.value.user.progress === 0,
  }
})
</script>
<template>
  <div
    v-if="session?.user"
    class="inline-flex items-center gap-x-1 rounded-full border py-1 pr-1.5 pl-2.5 text-xs font-medium"
    :class="classObject"
  >
    <AnimatedNumber :value="session.user.point_count" :decimals="0" />
    <svg
      v-if="session.user.progress > 0"
      class="size-3.5 shrink-0"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
    >
      <path
        d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
      />
    </svg>
    <svg
      v-else-if="session.user.progress < 0"
      class="size-3.5 shrink-0"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
    >
      <path
        d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
      />
    </svg>
    <SvgoPikaLightning v-else class="size-3 text-slate-700" />
  </div>
</template>
